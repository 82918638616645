.lava{
  filter: url("#plasma");
  position:absolute;
  height:100%;
  width:100%;
  top:0;
  left:0;
  z-index:-4;
}

.blob{
  filter: blur(4px);
  border-radius: 50%;
  position:absolute;
  z-index:-2;
  background: #6ffdfd;
  /* opacity: 30%; */
}
.blob.top{
  border-radius: 50%;
  width: 100%;
  height: 4%;
  top: -3%;
  left:0;
  
}
.blob.bottom{
  border-radius: 50%;
  width: 100%;
  height:4%;
  bottom: -2%;
  left:0;
}
.blob:nth-child(1){
  width: 100px;
  height: 100px;
  left: 35%;
  bottom:-15%;
 
  animation: wobble 4s ease-in-out alternate infinite, blob-one ease-in-out 13s infinite;
}
.blob:nth-child(2){
  width: 160px;
  height: 160px;
  right: 24%;
  bottom:-65%;
  
  animation: wobble 5s ease-in-out alternate infinite, blob-two ease-in-out 22s infinite;
}
.blob:nth-child(3){
  width: 150px;
  height: 150px;
  bottom:-15%;
  left: 34%;
  
  animation: wobble 6s ease-in-out alternate infinite, blob-three ease-in-out 16s infinite;
}
.blob:nth-child(4){
  width: 75px;
  height: 75px;
  bottom:-19%;
  left: 30%;
 

  animation: wobble 8s ease-in-out alternate infinite, blob-four ease-in-out 12s infinite;
}
.blob:nth-child(5){
  width: 55px;
  height: 55px;
  bottom:-25%;
  left: 34%;
 
  
  animation: wobble 9s ease-in-out alternate infinite, blob-five ease-in-out 32s infinite;
}
.blob:nth-child(6){
  width: 35px;
  height: 35px;
  bottom:-25%;
  right: 34%;

  
  animation:wobble 10s ease-in-out alternate infinite,  blob-six ease-in-out 12s infinite;
}
.blob:nth-child(7){
  width: 215px;
  height: 215px;
  bottom:-45%;
  right: 40%;

  
  animation: wobble 11s ease-in-out alternate infinite, blob-seven ease-in-out 32s infinite;
}
.blob:nth-child(8){
  width: 235px;
  height: 235px;
  bottom:-55%;
  right: 6%;
 
  
  animation: wobble 5s ease-in-out alternate infinite, blob-eight ease-in-out 22s infinite;
}
.blob:nth-child(9){
  width: 135px;
  height: 135px;
  bottom:-15%;
  right: 10%;

  
  animation: wobble 8s ease-in-out alternate infinite, blob-nine ease-in-out 13s infinite;
}
.blob:nth-child(10){
  width: 81px;
  height: 81px;
  bottom:-8%;
  left: 8%;
  
  
  animation: wobble 11s ease-in-out alternate infinite, blob-ten ease-in-out 12s infinite;
}

@keyframes blob-one{
  0%, 100%{
    transform: translatey(0);
  }
  50%{
    transform: translatey(-700%);
  }
}
@keyframes blob-two{
  0%, 100%{
    transform: translatey(0);
  }
  50%{
    transform: translatey(-420%);
  }
}
@keyframes blob-three{
  0%, 100%{
    transform: translatey(0);
  }
  50%{
    transform: translatey(-305%);
  }
}
@keyframes blob-four{
  0%, 100%{
    transform: translatey(0);
  }
  50%{
    transform: translatey(-605%);
  }
}
@keyframes blob-five{
  0%, 100%{
    transform: translatey(0);
  }
  50%{
     transform: translatey(-700%);
  }
}
@keyframes blob-six{
  0%, 100%{
    transform: translatey(0);
  }
  50%{
     transform: translatey(-700%);
  }
}
@keyframes blob-seven{
  0%, 100%{
    transform: translatey(0);
  }
  50%{
     transform: translatey(-300%);
  }
}
@keyframes blob-eight{
  0%, 100%{
    transform: translatey(0);
  }
  50%{
     transform: translatey(-900%);
  }
}
@keyframes blob-nine{
  0%, 100%{
    transform: translatey(0);
  }
  50%{
     transform: translatey(-500%);
  }
}
@keyframes blob-ten{
  0%, 100%{
    transform: translatey(0);
  }
  50%{
     transform: translatey(-1010%);
  }
}


@keyframes wobble {
  50% {
   border-radius: 72% 89% 76% 92% / 85% 70% 95% 71%;
  }
  100% {
   border-radius: 90% 72% 94% 86% / 72% 93% 78% 90%;
  }
}

